// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/login.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/login.tsx");
  import.meta.hot.lastModified = "1729813714033.6714";
}
// REMIX HMR END

import { useState } from "react";
import { Form, Link, useLoaderData, useNavigation } from "@remix-run/react";

//Auth
import { authenticator } from '~/auth/auth.server';

//Server
import { getSession } from '~/auth/session.server';

//Components
import Input from "~/components/input";
import Spinner from "~/components/spinner";

//Other
import { json } from "@remix-run/node";
export const meta = () => {
  return [{
    title: "Acceso | Grupo Sosa Morales"
  }, {
    name: "description",
    content: "Plataforma de archivos Grupo Sosa Morales"
  }, {
    charset: 'UTF-8'
  }, {
    httpEquiv: 'X-UA-Compatible',
    content: 'IE=edge'
  }, {
    name: 'viewport',
    content: 'width=device-width, initial-scale=1.0'
  }];
};
export async function loader({
  request
}) {
  await authenticator.isAuthenticated(request, {
    successRedirect: "/"
  });
  const session = await getSession(request.headers.get("cookie"));
  const error = session.get(authenticator.sessionErrorKey);
  return json({
    error
  });
}
export async function action({
  request,
  context
}) {
  console.log(context);
  return await authenticator.authenticate("user-pass", request, {
    successRedirect: "/",
    failureRedirect: "/login",
    throwOnError: true,
    context
  });
}
export default function Login() {
  _s();
  const navigation = useNavigation();
  const loader = useLoaderData();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  return <main className="login-modal">
      <Form className="form" method="POST">
        <h1 className="heading">Iniciar sesión</h1>
        <h2 className="subheading">
          Llena todos los campos para iniciar sesión
        </h2>
        <br />

        <div className="inputs">
          <Input title="Nombre de usuario o correo electronico" name="email" placeholder="Tu correo electronico" value={email} setValue={setEmail} error={""} />

          <Input title="Contraseña" name="password" placeholder="Tu contraseña" type="password" value={password} setValue={setPassword} error={""} />

          <div className="account-options">
            <Link to="/create-account" className="option">
              ¿No tienes una cuenta? Crea una
            </Link>
            <Link to="/forgot-password" className="option">
              ¿Olvidaste tu contraseña? Recuperala
            </Link>
          </div>

          <p className="error">{loader?.error?.message}</p>

          <div className="loading">
            <input className="button" type="submit" value="Iniciar Sesión" />
            {navigation?.state !== "idle" && <Spinner />}
          </div>
        </div>
      </Form>
    </main>;
}
_s(Login, "Qkn2zE9/d8YfhJV1mCq8S5lpZnk=", false, function () {
  return [useNavigation, useLoaderData];
});
_c = Login;
var _c;
$RefreshReg$(_c, "Login");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;